.smk_accordion {
  position: relative; }

.smk_accordion .accordion_in {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  margin-bottom: 10px; }

/* Head */
.smk_accordion .accordion_in .acc_head {
  position: relative;
  padding: 15px 20px;
  display: block;
  cursor: pointer;
  color: #333; }

.smk_accordion .accordion_in .acc_head span {
  font-weight: 600;
  font-size: 18px;
  color: #3c6eaf; }
  .smk_accordion .accordion_in .acc_head span span {
    background-image: url("../images/sprites.svg");
    background-position: -108px -476px;
    display: inline-block;
    height: 16px;
    width: 16px;
    position: relative;
    top: 2px;
    margin-left: 10px; }

.smk_accordion .accordion_in .acc_head p {
  padding: 0px !important;
  font-size: 11px;
  font-weight: normal; }

/* Content */
.smk_accordion .accordion_in .acc_content {
  padding: 0 20px 20px 20px;
  background: #fff;
  float: left;
  width: 100%; }

/* Icon expand */
.smk_accordion .accordion_in .acc_head .acc_icon_expand {
  background-image: url("../images/sprites.svg");
  background-position: -249px -389px;
  display: inline-block;
  height: 9px;
  width: 16px;
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -4px; }

.smk_accordion .accordion_in.acc_active .acc_head .acc_icon_expand {
  background-position: -249px -401px; }

/* JS requires */
.smk_accordion .accordion_in.acc_active .acc_content {
  display: block;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8fbff+0,ffffff+21 */
  background: #f8fbff;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f8fbff 0%, white 21%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f8fbff 0%, white 21%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f8fbff 0%, white 21%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8fbff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */ }

.smk_accordion.acc_with_icon .accordion_in .acc_head {
  padding-right: 40px; }

.acc_active {
  background: url(../images/gr.png) repeat-x top; }
  .acc_active .acc_head {
    padding-bottom: 5px !important; }
